











































































































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
import moment from 'moment';
import {placeModule, reservationModule} from '@/store';
import {EventBus} from '@/config/config';

@Component({
    components: {
        'alert-modal': () => import('@/components/popup/AlertModal.vue')
    }
})
export default class ReserveModal extends mixins(DataFormat) {
    @Prop({required: true})
    public place!: any;
    @Prop()
    public reserveParams!: {
        date: any;
        peopleCount: number;
        peopleDetailCount: object;
        time: string;
        tableType: string;
        requestText: string;
        listBuyOption: any;
        calendarTableType: string;
        listTime: any;
        totalPrice: number;
    };
    @Prop()
    public selectLang!: any;
    @Prop()
    public reservation!: any;

    public selectedDate: any = null;
    public countMaxPerson: number = 0;
    public detailCount: object = {};
    public isModalClose: boolean = false;
    public step: number = 1;
    public listMenu: any = [];
    // public selectedMenu: any = [];
    public errorMessage: string = '';
    public height: number = 134;
    public loading: boolean = false;
    public dataLoading: boolean = false;
    public optionTotalCount: number = 0;
    public resetData: any = [];
    public seatChangeText: string = '좌석을';
    public saveChangeData: any = {
        value: [],
        type: ''
    };
    public firstCount: number = 0;
    public $refs!: {
        calendar: any;
    };

    public mounted() {
        //modal 상태
        EventBus.$on('updateReserveModalState', (data) => {
            this.isModalClose = data;
            document.querySelector('html' as any).classList.add('open-modal');
        });

        EventBus.$on('setPeopleDetail', (data) => {
            this.setPersonDetail();
        });
    }

    public fetchPersonDetail(placeId: number) {
        placeModule.fetchPersonDetail(placeId).then((res: object) => {
            this.setPersonDetail();
        });
    }

    private setPersonDetail() {
        if (this.personDetail && this.personDetail.length) {
            if (this.reservation) { //예약 변경
                if (this.reservation.party_size_detail && this.reservation.party_size_detail.length > 0) { //인원 구분 적용 매장
                    for(let i=0; i<this.reservation.party_size_detail.length; i++) {
                        const item = this.reservation.party_size_detail[i];
                        this.reserveParams.peopleDetailCount[item.key] = item.party_size;
                        this.detailCount[item.key] = item.party_size;
                    }
                } else {
                    for(let i=0; i<this.personDetail.length; i++) {
                        const item = this.personDetail[i];
                        if (i === 0) {
                            this.reserveParams.peopleDetailCount[item.key] = this.reservation.party_size;
                            this.detailCount[item.key] = this.reservation.party_size;
                            continue;
                        }

                        this.reserveParams.peopleDetailCount[item.key] = 0;
                        this.detailCount[item.key] = 0;
                    }
                }

            } else { //예약 등록
                for(let i=0; i<this.personDetail.length; i++) {
                    const item = this.personDetail[i];
                    if (i === 0) {
                        this.reserveParams.peopleDetailCount[item.key] = this.reserveParams.peopleCount;
                        this.detailCount[item.key] = this.reserveParams.peopleCount;
                        continue;
                    }

                    this.reserveParams.peopleDetailCount[item.key] = 0;
                    this.detailCount[item.key] = 0;
                }
            }
        }
    }

    public fetchCalendar() {
        let params: object = {
            place_id: this.$route.params.placeId,
            table_type: this.reserveParams.tableType !== '' ? this.reserveParams.tableType : 'all'
        };

        if (this.reservation) {
            Object.assign(params, { place_id: this.reservation.place_id });
            Object.assign(params, { reservation_id: this.reservation.id });
        }
        this.dataLoading = true;
        reservationModule.fetchCalendar(params).then((res: any) => {
            this.fetchCapacity();
        });
    }

    public fetchCapacity(tableType?: any) {
        if (this.reserveParams.date === '') return;

        const capacityParams: any = {
            place_id: this.place.id,
            reservation_date: moment(this.reserveParams.date).format('YYYY-MM-DD'),
            table_type: this.reserveParams.tableType !== '' ? this.reserveParams.tableType : 'all'
        };
        if (tableType === 'all') {
            capacityParams.table_type = 'all';
        }

        if (this.reservation) {
            this.fetchPersonDetail(this.place.id);
            Object.assign(capacityParams, { reservation_id: this.reservation.id });
        }
        reservationModule.fetchCapacity(capacityParams).then((res: any) => {
            if (res.status) {
                if (this.personDetail) { //인원 구분 사용 시 최대 인원을 미리 구해야함
                    this.countMaxPerson = res.data[res.data.length - 1].size;
                    // for (const room of res.data) {
                    //     if (room.size > this.countMaxPerson) {
                    //         this.countMaxPerson = room.size;
                    //     }
                    // }
                }
                this._setListTime();
                if (res.data && res.data.length && this.reserveParams.date) {
                    this.selectedDate = '';
                    setTimeout(() => {
                        this.selectedDate = moment(this.reserveParams.date).format('YYYY-MM-DD');
                    });
                }
            }
        })
        .finally(() => {
            this.dataLoading = false;
        });
    }

    public todayClick() {
        this.selectedDate = moment().format('YYYY-MM-DD');
        this.reserveParams.date = moment().format('YYYY-MM-DD');
        this.$nextTick(() => {
            this.fetchCapacity();
            this.$refs.calendar.move(this.reserveParams.date);
        });
    }

    //예약 요청사항 height 체크
    public inputHeight() {
        const textarea = document.querySelector('.text-area.reservation' as any);
        if (textarea) {
            textarea.addEventListener('input', () => {
                if (this.reserveParams.requestText === '') {
                    this.height = 134;
                    return;
                }

                if (textarea.scrollHeight < 134) return;

                this.height = textarea.scrollHeight;
            });
        }
    }

    /**
     * 캘린더 오픈 시 가장 빠른 예약 가능한 날짜를 선택 된 상태로 보여주기 위해
     * 매장상세 하단 nearSchedule API 호출 이후로 캘린더 호출
     **/
    @Watch('reserveParams.listTime')
    public fetchListTime() {
        if (!this.calendar) {
            this.fetchCalendar();
            this.fetchPersonDetail(this.place.id);
        }
    };

    // 날짜 선택
    public selectReserveDate(e: any) {
        if (this.calendar.list_off_date.length) {
            const re = this.calendar.list_off_date.filter((row: any) => {
                return row === e.id;
            });
            if (re.length) {
                return;
            } else {
                this.reserveParams.date = moment(this.selectedDate).format('YYYY-MM-DD');
                this.fetchCapacity();
            }
        } else {
            this.reserveParams.date = moment(this.selectedDate).format('YYYY-MM-DD');
            this.fetchCapacity();
        }
    }

    // 인원 선택
    public selectPeopleCount() {
        if (!this.capacity) return;

        this._setListTime();
    }

    // 인원 선택 (인원구분)
    public selectDetailPeopleCount(key: string, people: number) {
        //같은 블록을 누르면 해제처리
        if (this.reserveParams.peopleDetailCount[key] === people) {
            this.reserveParams.peopleDetailCount[key] = this.detailCount[key] = 0;
        } else {
            this.reserveParams.peopleDetailCount[key] = this.detailCount[key] = people;
        }


        let peopleCount = 0;
        const entries = Object.entries(this.detailCount);
        for (const [index, value] of entries) {
            peopleCount += value;
        }

        if (this.reserveParams.peopleCount !== peopleCount) {
            this.reserveParams.peopleCount = peopleCount;
            this._setListTime();
        }
    }

    public personDisable(key: string, person: number) {
        if (this.reservation) {
            if (this.reserveParams.peopleDetailCount[key] === person) return false;
            return true;
        } else {
            const re = this.capacity.filter((list: any) => list.size === person && !list.is_active);
            const activeSize = this.capacity.filter((list: any) => list.is_active);
            let maxCount = this.countMaxPerson;
            if (activeSize.length && activeSize[activeSize.length - 1].size !== this.countMaxPerson) {
                maxCount = activeSize[activeSize.length - 1].size;
            }
            const letAt: number = maxCount - this.reserveParams.peopleCount;
            const eachMax: number = this.detailCount[key] ? this.detailCount[key] + letAt : letAt;
            if (re.length && activeSize.length && activeSize[activeSize.length - 1].size < person) {
                return true;
            }
            return person > eachMax;
        }
    }

    @Watch('isModalClose')
    public watchModal() {
        this.activeSwiperTime();
    }

    /**
     * 인원수에 맞는 타임블럭 세팅
     * @private
     */
    private _setListTime() {
        let data: any = null;
        if (this.capacity) {
            data = this.capacity.find(item => item.size === this.reserveParams.peopleCount);
        }
        if (!data) {
            // this.reserveParams.listTime = null;
            this.reserveParams.time = '';
            return;
        }

        this.reserveParams.listTime = data.times || null;

        // if (!data.is_active) this.reserveParams.listTime = null;
        // else this.reserveParams.listTime = data.times || null;

        this.activeSwiperTime();
    }

    /**
     * 타임블럭 생성 및 포커스 설정
     */
    public activeSwiperTime() {

        let index = 0;
        if (this.reserveParams.listTime && this.reserveParams.time) {
            //선택한 시간에 해당되는 타임블럭의 index get
            index = this.reserveParams.listTime.findIndex(item => item.time === this.reserveParams.time);

            //타임블럭에 시간이 없으면 초기화
            if (index === -1) {
                this.reserveParams.time = '';
                index = 0;
            } else {
                //시간이 비활성화 된 상태일때 값 초기화
                // if (!this.reserveParams.listTime[index].is_active) {
                //     this.reserveParams.time = '';
                // }
            }
        }
        new Swiper('.person-area .swiper-container', {
            initialSlide: 0,
            slidesPerView: 'auto',
            freeMode: true,
            observer: true,
            observeParents: true,
        });

        new Swiper('.time-block-area .swiper-container', {
            initialSlide: index,
            slidesPerView: 'auto',
            freeMode: true,
            observer: true,
            observeParents: true,
        });

        new Swiper('.table-type-area .swiper-container', {
            initialSlide: index,
            slidesPerView: 'auto',
            freeMode: true,
            observer: true,
            observeParents: true,
        });
    }

    public updateStep(value) {
        if (value === 2) {
            if (!this.isNext) return;
            if (this.dataLoading) {
                return;
            }

            const menuParams: any = {
                place_id: this.place.id,
                reservation_date: moment(this.reserveParams.date).format('YYYY-MM-DD'),
                reservation_time: this.reserveParams.time,
                number_of_people: this.reserveParams.peopleCount
            };

            reservationModule.fetchMenu(menuParams).then((res: any) => {
                if (res.status) {
                    this.step = value;
                    if (
                        this.menu.data[0] &&
                        this.menu.data[0].table_type &&
                        this.reserveParams.calendarTableType === 'all'
                    ) {
                        this.reserveParams.tableType = this.menu.data[0].table_type;
                    } else {
                        this.reserveParams.tableType = this.reserveParams.calendarTableType;
                    }

                    // this.selectedMenu = [];
                    this.menu.data.forEach((element) => {
                        if (element.table_type === this.reserveParams.tableType) this.listMenu = element.groups;
                    });
                    this.optionCountReset();
                    this.$nextTick(() => {
                        this.watchListMenu();
                        this.$forceUpdate();
                    });
                }
            });
        } else if (value === 1) {
            this.step = value;
            // this.selectedMenu = [];
            // this.resetMenu();
            setTimeout(() => {
                this.activeSwiperTime();
            }, 100);
        }
    }

    // @Watch('listMenu', {
    //     deep: true
    // })
    // public watchListMenu() {
    //     this.optionTotalCount = 0;
    //     this.reserveParams.totalPrice = 0;
    //     this.listMenu.forEach((row: any, index: any) => {
    //         row.group_total_count = 0;
    //         row.options.forEach((element: any) => {
    //             if (element.buy_count) {
    //                 this.reserveParams.totalPrice += (element.discount_price * element.buy_count);
    //                 this.optionTotalCount += element.buy_count;
    //                 row.group_total_count += element.buy_count;
    //             } else {
    //                 this.optionCountReset();
    //             }
    //         });
    //     });
    // }

    @Watch('step')
    public watchListMenu(newValue?: any, oldValue?: any) {
        if (this.step === 2) {
            this.optionTotalCount = 0;
            this.reserveParams.totalPrice = 0;
            this.listMenu.forEach((row: any, index: any) => {
                row.group_total_count = 0;
                row.options.forEach((element: any) => {
                    if (element.buy_count >= 0) {
                        this.reserveParams.totalPrice += (element.discount_price * element.buy_count);
                        this.optionTotalCount += element.buy_count;
                        row.group_total_count += element.buy_count;
                    } else {
                        this.optionCountReset();
                        this.$nextTick(() => {
                            this.reserveParams.totalPrice += (element.discount_price * element.buy_count);
                            this.optionTotalCount += element.buy_count;
                            row.group_total_count += element.buy_count;
                        });
                    }
                });
            });
        } else if (oldValue === 2 && this.step === 1) {
            this.fetchCapacity('all');
        }
    }

    public optionCountReset(reset?: any) {
        if (this.listMenu.length > 1) {
            this.listMenu.forEach((row: any, index: any) => {
                if (row.group_total_count) {
                        delete row.group_total_count;
                    }
                    row.options.forEach((element: any) => {
                        if (!element.buy_count) {
                            element.buy_count = 0;
                        }
                        if (reset) {
                            element.buy_count = 0;
                        }
                    });
                });
            } else {
                if (this.listMenu[0]) {
                    this.listMenu[0].options.forEach((element: any) => {
                    if (this.listMenu[0].group_total_count) {
                        delete this.listMenu[0].group_total_count;
                    }
                    if (!element.buy_count) {
                        if (this.listMenu[0].options.length > 1) {
                            element.buy_count = 0;
                        } else {
                            if (this.reserveParams.peopleCount > element.min_count) {
                                if (this.listMenu[0].check_count_type === 'no') {
                                    element.buy_count = element.min_count;
                                } else {
                                    if (
                                        this.listMenu[0].check_count_type === 'min' &&
                                        element.standard_stock > 1 &&
                                        this.listMenu[0].options.length === 1
                                    ) {
                                        element.buy_count = Math.ceil(this.reserveParams.peopleCount / element.standard_stock);
                                        return;
                                    }
                                    element.buy_count = this.reserveParams.peopleCount;
                                }
                            } else {
                                element.buy_count = element.min_count;
                            }
                        }
                    }
                    this.$nextTick(() => {
                        if (this.listMenu[0].is_use_people_type) {
                            element.buy_count = this.detailCount[element.os_people_type_id];
                            if (!this.listMenu[0].is_active) {
                                element.buy_count = 0;
                            }
                            // if (this.listMenu[0].options.length === 1) {
                            //     element.buy_count = this.reserveParams.peopleCount;
                            // } else {
                            //     element.buy_count = this.detailCount[element.os_people_type_id];
                            // }
                        }
                    });
                    if (reset || !element.is_active || !this.listMenu[0].is_active) {
                        element.buy_count = 0;
                    }
                });
            }
        }
        this.$nextTick(() => {
            this.$forceUpdate();
        });
    }

    //인원수 변경
    public changeQuantity(index: number, i: number, count: number) {
        let menuItem = {...this.listMenu[index]};
        const options = menuItem.options[i];
        const {min_count: minCount, max_count: maxCount, is_active: isActive, standard_stock: standardStock} = options;
        if (
            this.listMenu.length > 1
        ) {
            const re = this.listMenu.findIndex((row: any, rowIndex: any) => row.group_total_count > 0 && index !== rowIndex);
            if (re !== -1) {
                this.saveChangeData = {
                    value: re,
                    type: 'menu',
                    save: {
                        index, i, count
                    }
                };
                this.seatChangeText = '메뉴를';
                this.$modal.show('seatChange');
                return;
            }
        }
        if (menuItem.is_active && isActive) {
            if (
                menuItem.options.length === 1 &&
                options.buy_count === 0 &&
                count === 1 &&
                (menuItem.check_count_type === 'total' || menuItem.check_count_type === 'min')
            ) {
                if (
                    standardStock > 1 &&
                    menuItem.check_count_type === 'min'
                ) {
                    options.buy_count = Math.ceil(this.reserveParams.peopleCount / standardStock);
                } else {
                    options.buy_count = this.reserveParams.peopleCount;
                }
            } else if (
                options.buy_count === 0 &&
                count === 1 &&
                minCount > 1
            ) {
                if (
                    standardStock > 1 &&
                    menuItem.check_count_type === 'min' &&
                    menuItem.options.length === 1
                ) {
                    options.buy_count = Math.ceil(this.reserveParams.peopleCount / standardStock);
                } else {
                    options.buy_count = minCount;
                }
            } else if (
                menuItem.options.length > 1 &&
                minCount > 1 &&
                count === -1 &&
                options.buy_count <= minCount
            ) {
                options.buy_count = 0;
            } else {
                options.buy_count += count;
            }

            // if ((minCount > options.buy_count || maxCount < options.buy_count) && menuItem.check_count_type === 'none') {
            //     console.log('222');
            //     if (minCount > options.buy_count && options.buy_count !== minCount) {
            //         options.buy_count = minCount;
            //     }
            //     if (maxCount < options.buy_count && options.buy_count !== maxCount) {
            //         options.buy_count = maxCount;
            //     }
            //     this.errorMessage = `해당 상품은 최소 ${minCount}매, 최대 ${maxCount}매 구매 가능합니다.`;
            //     EventBus.$emit('errorMessage', this.errorMessage);
            //     this.$modal.show('alert');
            //     return;
            // }
            this.$set(this.listMenu[index].options, i, options);
            this.watchListMenu();
            // this.selectedMenuUpdate();
        }
    }
    // public changeQuantity(index, count) {
    //     let menuItem = {...this.listMenu[index]};
    //     const {min_count: minCount, max_count: maxCount} = menuItem;

    //     menuItem.buy_count += count; // buy_count 수정

    //     if (minCount > menuItem.buy_count || maxCount < menuItem.buy_count) {
    //         this.errorMessage = `해당 상품은 최소 ${minCount}매, 최대 ${maxCount}매 구매 가능합니다.`;
    //         EventBus.$emit('errorMessage', this.errorMessage);
    //         this.$modal.show('alert');

    //         return;
    //     }

    //     this.$set(this.listMenu, index, menuItem);
    //     this.selectedMenuUpdate();
    // }
    
    // 최소/최대 인원 체크
    public countDisabled(index: number, i: number, type: any) {
        let menuItem = {...this.listMenu[index]};
        const options = menuItem.options[i];
        const {min_count: minCount, max_count: maxCount, is_active: isActive, standard_stock: standardStock} = options;
        if (!isActive || menuItem.is_use_people_type) {
            return true;
        }
        if (options.buy_count === 0 && type === 'min') {
            return true;
        }
        if (menuItem.check_count_type === 'no') {
            if (type === 'max' && options.buy_count === maxCount) {
                return true;
            }
            if (
                menuItem.options.length === 1 &&
                options.buy_count === minCount &&
                type === 'min'
            ) {
                return true;
            }
        }
        if (menuItem.check_count_type === 'min') {
            if (menuItem.is_use_people_type) {
                if (this.reserveParams.peopleCount === this.optionTotalCount) {
                    //
                } else if (this.reserveParams.peopleCount > this.optionTotalCount) {
                    //
                }
            } else {
                if (this.reserveParams.peopleCount >= this.optionTotalCount) {
                    if (type === 'min') {
                        if (
                            standardStock > 1 &&
                            menuItem.options.length === 1 &&
                            (Math.ceil(this.reserveParams.peopleCount / standardStock) === this.optionTotalCount)
                        ) {
                            return true;
                        } else if (
                            menuItem.options.length > 1 &&
                            standardStock > 1 &&
                            this.optionTotalCount < minCount
                        ) {
                            return true;
                        } else if (
                            menuItem.options.length === 1 &&
                            standardStock === 1
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    if (type === 'max' && options.buy_count >= maxCount) {
                        return true;
                    }
                } else {
                    if (type === 'max' && options.buy_count >= maxCount) {
                        return true;
                    }
                }
            }
        }
        if (menuItem.check_count_type === 'total') {
            if (menuItem.is_use_people_type) {
                if (this.reserveParams.peopleCount === this.optionTotalCount) {
                    return true;
                } else if (this.reserveParams.peopleCount > this.optionTotalCount) {
                    //
                }
            } else {
                if (this.reserveParams.peopleCount <= this.optionTotalCount) {
                    if (type === 'max') {
                        if (menuItem.group_total_count === 0) {
                            return false;
                        }
                        return true;
                    }
                    if (menuItem.options.length === 1) {
                        if (type === 'min') {
                            return true;
                        }
                    }
                } else if (this.reserveParams.peopleCount > this.optionTotalCount) {
                    if (menuItem.options.length > 1) {
                        if (type === 'min' && this.optionTotalCount === 0) {
                            return true;
                        }
                    } else {
                        if (type === 'min') {
                            if (this.optionTotalCount >= minCount) {
                                return true;
                            }
                        }
                    }
                }
            }
        }
        return false;
    }


    // public deleteOption(index: number) {
    //     this.$set(this.selectedMenu, index, false);

    //     this.resetBuyCount(index);
    // }

    private resetMenu() {
        for (let i = 0; i < this.listMenu.length; i++) {
            this.resetBuyCount(i);
        }
    }

    private resetBuyCount(index: number) {
        let menuItem = {...this.listMenu[index]};
        menuItem.options.forEach((row: any, i: any) => {
            menuItem.options[i].buy_count = this.listMenu[index].options[i].min_count;
            this.$set(this.listMenu[index].option, i, menuItem.options[i]);
        })
    }

    // 옵션 선택
    public selectOption(event: any, index: number) {
        const {checked, type} = event.target;

        if (type === 'checkbox') {
            if (checked === false) this.resetBuyCount(index);
        }
    }

    // 팝업 닫기
    public toReserveClose() {
        this.isModalClose = true;

        setTimeout(() => {
            this.$modal.hide('reserve');
        }, 650);
    }
    public resetClick() {
        if (
            this.listMenu.length === 1
        ) {
            if (this.listMenu[0].is_use_people_type) {
                return;
            } else {
                if (
                    (this.listMenu[0].check_count_type === 'total' && this.listMenu[0].options.length === 1) ||
                    this.optionTotalCount === 0
                ) {
                    return;
                }
            }
        } else if (this.listMenu.length > 1 && this.optionTotalCount === 0) {
            return;
        }
        this.$modal.show('reset');
    }
    public resetConfirm() {
        // this.optionCountReset(true);
        // this.watchListMenu();
        if (
            this.menu.data[0] &&
            this.menu.data[0].table_type &&
            this.reserveParams.tableType !== this.menu.data[0].table_type
        ) {
           this.reserveParams.tableType = this.menu.data[0].table_type; 
        } else {
            this.updateListOption(true);
        }
        this.$modal.hide('reset');
    }

    // 모달 상태 이벤트
    public modalOpened() {
        document.querySelector('html' as any).classList.add('open-modal');
        const re = this.calendar.list_off_date.map((list: any) => list === this.reserveParams.date);
        if (!re) {
            this.selectedDate = this.reserveParams.date;
        } else {
            //
        }

        if (this.reservation) {
            this.isModalClose = false;
            this.fetchCalendar();
        } else {
            this.activeSwiperTime();
        }

        // this.setPersonDetail()

    }

    public optionChangeReset() {
        if (this.saveChangeData.type === 'menu') {
            this.listMenu[this.saveChangeData.value].group_total_count = 0;
            this.listMenu[this.saveChangeData.value].options.forEach((row: any) => {
                row.buy_count = 0;
            });
            this.changeQuantity(this.saveChangeData.save.index, this.saveChangeData.save.i, this.saveChangeData.save.count);
        } else if (this.saveChangeData.type === 'seat') {
            this.reserveParams.tableType = this.saveChangeData.value;
        }
        this.$modal.hide('seatChange');
    }


    public modalClosed() {
        const {date, peopleCount} = this.reserveParams;

        this.isModalClose = true;

        if (date === '' || peopleCount === 0) {
            EventBus.$emit('updateInitNearSchedule');
        }

        // this.detailCount = this.reserveParams.peopleDetailCount = {};

        // this.$modal.hide('reserve');
    }

    public destroyed() {
        document.querySelector('html' as any).classList.remove('open-modal');
    }

    public clickTime(event: any, time: string, index: number) {
        if (event.srcElement.classList.value.includes('disabled')) return;

        this.reserveParams.time = time;
        //
        // if (event.srcElement.classList.value.includes('disabled')) {
        //     return;
        // } else if (event.srcElement.classList.value.includes('active')) {
        //     this.personKind[index].person = 0;
        //     if (this.personKind[index] && this.personKind[index].name && this.personKind[index].name.includes('유아')) {
        //         this.params.except_person = 0;
        //     }
        // } else {
        //     this.personKind[index].person = person;
        //     if (this.personKind[index] && this.personKind[index].name && this.personKind[index].name.includes('유아')) {
        //         this.params.except_person = person;
        //     }
        // }
    }


    get calendar() {
        return reservationModule.calendar;
    }

    get listTableType() {
        if (reservationModule.calendar && reservationModule.calendar.list_table_type) {
            return reservationModule.calendar.list_table_type;
        }
        return null;
    }

    get capacity() {
        return reservationModule.capacity;
    }

    get menu() {
        return reservationModule.menu;
    }

    get personDetail() {
        return placeModule.personDetail;
    }

    get isNext() {
        switch (this.step) {
            case 1:
                return !!this.reserveParams.time;
            case 2:
                if (this.reservation) { //예약 변경하기
                    return true;
                }

                if (this.place.reservation_method !== 'option') {
                    return true;
                }

                return false;
            default:
                return false; // 혹은 다른 기본값 처리
        }
    }
    // 인원 수량 체크
    public peopleCountCheck() {
        if (this.listMenu.length > 1) {
            const re = this.listMenu.findIndex((list: any) => list.group_total_count > 0);
            if (re === -1) {
                return false;
            } else {
                // standard_stock이 2이상일 경우
                const useSet = this.listMenu[re].options.filter((list: any) => list.standard_stock > 1);
                if (this.listMenu[re].check_count_type === 'no') {
                    return this.optionTotalCount > 0;
                } else if (
                    this.listMenu[re].check_count_type === 'min' &&
                    useSet.length
                ) {
                    let totalCount: any = 0;
                    this.listMenu[re].options.filter((list: any) => {
                        return totalCount += (list.buy_count * list.standard_stock);
                    });
                    return this.reserveParams.peopleCount <= totalCount;
                } else {
                    if (this.listMenu[re].check_count_type === 'total') {
                        let totalCount = 0;
                        this.listMenu[re].options.forEach((option: any) => {
                            if (option.buy_count > 0) {
                                totalCount += (option.buy_count * option.standard_stock);
                            }
                        });
                        return this.reserveParams.peopleCount === totalCount && this.reserveParams.totalPrice > 0;
                    } else if (this.listMenu[re].check_count_type === 'min') {
                        let totalCount = 0;
                        this.listMenu[re].options.forEach((option: any) => {
                            if (option.buy_count > 0) {
                                totalCount += (option.buy_count * option.standard_stock);
                            }
                        });
                        return this.reserveParams.peopleCount <= totalCount && this.reserveParams.totalPrice > 0;
                    } else {
                        return false;
                    }
                }
            }
        } else if (this.listMenu && this.listMenu[0]) {
            // standard_stock이 2이상일 경우
            const useSet = this.listMenu[0].options.filter((list: any) => list.standard_stock > 1);
            if (this.listMenu[0].check_count_type === 'no') {
                return this.optionTotalCount > 0;
            } else if (
                this.listMenu[0].check_count_type === 'min' &&
                useSet.length
            ) {
                let totalCount: any = 0;
                this.listMenu[0].options.filter((list: any) => {
                    return totalCount += (list.buy_count * list.standard_stock);
                });
                return this.reserveParams.peopleCount <= totalCount;
            } else {
                if (this.listMenu[0].check_count_type === 'total') {
                    let totalCount = 0;
                    this.listMenu[0].options.forEach((option: any) => {
                        if (option.buy_count > 0) {
                            totalCount += (option.buy_count * option.standard_stock);
                        }
                    });
                    return (this.reserveParams.peopleCount === totalCount) && (this.reserveParams.totalPrice > 0);
                } else if (this.listMenu[0].check_count_type === 'min') {
                    let totalCount = 0;
                    this.listMenu[0].options.forEach((option: any) => {
                        if (option.buy_count > 0) {
                            totalCount += (option.buy_count * option.standard_stock);
                        }
                    });
                    return this.reserveParams.peopleCount <= totalCount && this.reserveParams.totalPrice > 0;
                } else {
                    return false;
                }
            }
        } else {
            return true;
        }
    }

    get titleText() {
        if (this.reservation) return '변경된 예약정보 확인하기';

        if (this.place.reservation_method === 'option') return '메뉴 선택하기';

        return '요청사항 작성하기';
    }

    @Watch('reserveParams.tableType', {
        deep: true
    })
    public updateListOption(reset?: any) {
        this.firstCount++;
        // this.selectedMenu = [];
        if (this.menu && this.menu.data) {
            this.menu.data.forEach((element) => {
                element.groups.forEach((group: any) => {
                    delete group.group_total_count;
                    group.options.forEach((option: any) => {
                        delete option.buy_count;
                    });
                });
                this.$nextTick(() => {
                    if (element.table_type === this.reserveParams.tableType) this.listMenu = element.groups;
                });
            });
        }
        this.$nextTick(() => {
            if (this.firstCount > 1) {
                this.watchListMenu();
            }
            this.fetchCalendar();
        });
    }
    // @Watch('reserveParams.calendarTableType')
    // public tableTypeUpdate() {
    //     this.fetchCalendar();
    // }

    public seatChangeClick(key: any) {
        if (this.step === 2 && key !== this.reserveParams.tableType && this.optionTotalCount > 0) {
            this.saveChangeData = {
                value: key,
                type: 'seat'
            };
            this.seatChangeText = '좌석을';
            this.$modal.show('seatChange');
            return;
        }
        this.reserveParams.tableType = key;
    }

    public submit() {
        if (this.loading) {
            return;
        }
        this.loading = true;
        if (!this.reservation) {
            this.reserveParams.listBuyOption = [];

            // this.selectedMenu.forEach((isSelected, index) => {
            //     if (isSelected) {
            //         const {id, buy_count} = this.listMenu[index];
            //         this.reserveParams.listBuyOption.push({id, count: buy_count});
            //     }
            // });
            this.listMenu.forEach((list: any) => {
                if (list.group_total_count > 0) {
                    list.options.forEach((option: any) => {
                        if (option.buy_count > 0) {
                            this.reserveParams.listBuyOption.push({
                                id: Number(option.id),
                                count: Number(option.buy_count)
                            });
                        }
                    });
                }
            });

            this.$modal.show('reserveInfo');
            this.loading = false;
        } else { //예약 변경
            const {time, tableType, peopleCount, requestText} = this.reserveParams;
            const date = moment(this.reserveParams.date).format('YYYY-MM-DD');

            const payload: any = {
                reservationId: this.reservation.id,
                date: moment(`${date} ${time}`).format('YYYY-MM-DD HH:mm:ss'),
                message: requestText,
                person: peopleCount,
                tableType: tableType
            };

            //인원 구분 데이터 병합
            if (this.reserveParams.peopleDetailCount) {
                Object.entries(this.reserveParams.peopleDetailCount).forEach(([key, value]) => {
                    payload[`person_${key}`] = value;
                });
            }

            reservationModule
                .fetchReservationChange(payload)
                .then((res) => {
                    if (!res.status) {
                        this.errorMessage = res.error.message;
                        EventBus.$emit('errorMessage', this.errorMessage);
                        this.$modal.show('alert');
                    } else {
                        if (
                            this.place.reservation_method === 'normal' ||
                            (this.place.reservation_method === 'os_normal' && this.place.reservation_method.use_realtime_reserve !== 1) ||
                            (this.place.reservation_method === 'option' && this.place.reservation_method.use_realtime_reserve !== 1)
                        ) {
                            this.$q.notify('예약 변경이 요청되었습니다.');
                        } else {
                            this.$q.notify('예약 변경이 완료되었습니다.');
                        }
                        this.$emit('refresh');
                        document.querySelector('html' as any).classList.remove('open-modal');
                        this.step = 1;
                        this.$modal.hide('reserve');
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }

    @Watch('isModalClose')
    public isModalCloseUpdate() {
        if (this.isModalClose) {
            document.querySelector('html' as any).classList.remove('open-modal');
        } else {
            document.querySelector('html' as any).classList.add('open-modal');
        }
    }
    // @Watch('selectedMenu')
    // public selectedMenuUpdate() {
    //     this.reserveParams.totalPrice = 0;
    //     this.selectedMenu.forEach((isSelected, index) => {
    //         if (isSelected) {
    //             const {discount_price, buy_count} = this.listMenu[index];
    //             const addPrice = discount_price * buy_count;
    //             this.reserveParams.totalPrice = this.reserveParams.totalPrice + addPrice;
    //         }
    //     });
    // }

    get reservationAddInfo() {
        return reservationModule.reservationAddInfo;
    }

    get timeBlockShow() {
        if (this.calendar.list_off_date) {
            const re = this.calendar.list_off_date.findIndex((list: any) => list === this.reserveParams.date);
            if (re !== -1) {
                return false;
            } else if (!this.reserveParams.date) {
                return false;
            }
            return true;
        } else if (!this.reserveParams.date) {
            return false;
        }
        return this.reserveParams.listTime;
    }
    get personShow() {
        if (this.calendar.list_off_date) {
            const re = this.calendar.list_off_date.findIndex((list: any) => list === this.reserveParams.date);
            if (re !== -1) {
                return false;
            } else if (!this.reserveParams.date) {
                return false;
            }
            return true;
        } else if (!this.reserveParams.date) {
            return false;
        } else {
            return true;
        }
    }
    get resetDisabled() {
        if (
            this.listMenu.length === 1
        ) {
            if (this.listMenu[0].is_use_people_type) {
                return true;
            } else {
                if (
                    (this.listMenu[0].check_count_type === 'total' && this.listMenu[0].options.length === 1) ||
                    this.optionTotalCount === 0
                ) {
                    return true;
                }
            }
            return false;
        } else if (this.listMenu.length > 1 && this.optionTotalCount === 0) {
            return true;
        } else {
            return false;
        }
    }
}
